import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import Img from "gatsby-image"

import Zoom from "react-reveal/Zoom"
import Fade from "react-reveal/Fade"

import Layout from "../components/layout"

import { Avatar } from "antd"
import { UserOutlined } from "@ant-design/icons"

import SEO from "../components/seo"
import {
  OfferWrapper,
  CompaniesWrapper,
  AboutUsWrapper,
  OfferButton,
  StatisticsWrapper,
  OpinionsWrapper,
} from "./_index.styles"

import creators from "../creators.json"
import { useCountUp } from "react-countup"
import HeaderComponent from "../components/Header/Header.component"

const opinions = [
  {
    author: "Levinho",
    content: `Orlovski Agency is the best! I'm seriously glad to work them. I wouldn't be where I am today without them. They are very professional and quick to respond. I highly recommend Orlovski Agency to anyone who want professional work to be done fast and well!`,
  },
  {
    author: "Sevou",
    content: `My experience with Orlovage has been entirely positive. The company has a very professional attitude and the staff I communicated with was helpful and attentive to detail. Orlovski Agency has proven to be dependable and efficient, I thoroughly recommend working with them and would like to do so again myself in the future.`,
  },
]

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      youtubers: file(relativePath: { eq: "youtubers.png" }) {
        childImageSharp {
          fixed(height: 122) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      companies: file(relativePath: { eq: "companies.png" }) {
        childImageSharp {
          fixed(height: 122) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      phoneFrame: file(relativePath: { eq: "phone-frame.png" }) {
        childImageSharp {
          fixed(width: 717) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      star: file(relativePath: { eq: "star.png" }) {
        childImageSharp {
          fixed(width: 16) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      sliderInstagram: file(relativePath: { eq: "slider-instagram.png" }) {
        childImageSharp {
          fixed(width: 79) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      sliderTwitter: file(relativePath: { eq: "slider-twitter.png" }) {
        childImageSharp {
          fixed(width: 59) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      sliderYoutube: file(relativePath: { eq: "slider-youtube.png" }) {
        childImageSharp {
          fixed(width: 79) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      g2a: file(relativePath: { eq: "brands/g2a.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      habby: file(relativePath: { eq: "brands/habby.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      igg: file(relativePath: { eq: "brands/igg.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      mubi: file(relativePath: { eq: "brands/mubi.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      plarium: file(relativePath: { eq: "brands/plarium.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      razer: file(relativePath: { eq: "brands/razer.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const countUp1 = useCountUp({
    end: 30000000,
    duration: 3,
    separator: " ",
    suffix: "+",
  })
  const countUp2 = useCountUp({
    end: 100000000,
    duration: 3,
    separator: " ",
    suffix: "+",
  })
  const countUp3 = useCountUp({ end: 30, duration: 3, suffix: "+" })

  const [phoneSliderIndex, setPhoneSliderIndex] = useState(0)
  useEffect(() => {
    const timeout = setTimeout(
      () =>
        setPhoneSliderIndex(
          phoneSliderIndex === creators.length - 1 ? 0 : phoneSliderIndex + 1
        ),
      3000
    )

    return () => clearTimeout(timeout)
  }, [phoneSliderIndex, setPhoneSliderIndex])

  return (
    <Layout>
      <HeaderComponent />
      <SEO title="Orlovage - backbone of influencers face of the brands" />
      <OfferWrapper>
        <div className="first-section-item">
          <Zoom>
            <Img
              fixed={data.youtubers.childImageSharp.fixed}
              objectFit="contain"
            />
          </Zoom>
          <Fade left>
            {/* <Bounce> */}
            <h3>For influencers</h3>
          </Fade>
          <Fade left delay={500}>
            {/* </Bounce> */}
            <p>
              Are you an influencer? You want to start making money from
              sposnored content? Orlovski Agency can help you with that! We can
              find you deals on monthly basis without you doing anything!
              Influencers should focus on creating content while we think about
              getting money for them! Want to work with us? Contact us via
              ,,Contact for influencers’’ button!
            </p>
            <Fade left delay={1000}>
              <Link to="/contact">
                <OfferButton
                  background="#c20049"
                  boxShadowColor="#c20049a0"
                  boxShadowColorHover="#c20049c0"
                >
                  CONTACT FOR INFLUENCERS
                </OfferButton>
              </Link>
            </Fade>
          </Fade>
        </div>
        <div className="first-section-item">
          <Zoom>
            <Img
              fixed={data.companies.childImageSharp.fixed}
              objectFit="contain"
            />
          </Zoom>
          <Fade right>
            <h3>For companies</h3>
          </Fade>
          <Fade right delay={500}>
            <p>
              Do you want to promote your product or service? You dont know how
              to do it? We will take care of that! We can promote your brand
              using our influencers and social media. Campaigns,single promo
              videos, longterm contracts with influencers we will take care of
              everything! You just relax and see your company grow. Want to work
              with us? Contact us via ,,Contact for brands’’ button!
            </p>
          </Fade>
          <Fade right delay={1000}>
            <Link to="/contact">
              <OfferButton
                background="#2e0f7b"
                boxShadowColor="#2e0f7ba0"
                boxShadowHover="#2e0f7bc0"
              >
                CONTACT FOR BRANDS
              </OfferButton>
            </Link>
          </Fade>
        </div>
      </OfferWrapper>
      <CompaniesWrapper>
        <Fade bottom>
          <a
            href="https://g2a.com"
            rel="noopener noreferrer"
            className="logo"
            target="_blank"
          >
            <Img fixed={data.g2a.childImageSharp.fixed} alt="G2A" />
          </a>
          <a
            href="https://razer.com"
            rel="noopener noreferrer"
            className="logo"
            target="_blank"
          >
            <Img fixed={data.razer.childImageSharp.fixed} alt="Razer" />
          </a>
          <a
            href="https://mubi.pl"
            rel="noopener noreferrer"
            className="logo"
            target="_blank"
          >
            <Img fixed={data.mubi.childImageSharp.fixed} alt="Mubi" />
          </a>
          <a
            href="https://habby.fun"
            rel="noopener noreferrer"
            className="logo"
            target="_blank"
          >
            <Img fixed={data.habby.childImageSharp.fixed} alt="Habby" />
          </a>
          <a
            href="https://plarium.com"
            rel="noopener noreferrer"
            className="logo"
            target="_blank"
          >
            <Img fixed={data.plarium.childImageSharp.fixed} alt="Plarium" />
          </a>
          <a
            href="https://igg.com"
            rel="noopener noreferrer"
            className="logo"
            target="_blank"
          >
            <Img fixed={data.igg.childImageSharp.fixed} alt="IGG" />
          </a>
        </Fade>
      </CompaniesWrapper>
      <AboutUsWrapper>
        <div className="triangle" />
        <div className="about-us-grid">
          <div className="slider-wrapper">
            {creators[phoneSliderIndex].instagram && (
              <a
                href={creators[phoneSliderIndex].instagram}
                className="instagram"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src={require("../images/instagram-slider.svg")}
                  alt="instagram"
                />
              </a>
            )}
            {creators[phoneSliderIndex].twitter && (
              <a
                href={creators[phoneSliderIndex].twitter}
                className="twitter"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src={require("../images/twitter-slider.svg")}
                  alt="instagram"
                />
              </a>
            )}
            {creators[phoneSliderIndex].youtube && (
              <a
                href={creators[phoneSliderIndex].youtube}
                className="youtube"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={require("../images/yt-slider.svg")} alt="instagram" />
              </a>
            )}
            <div className="text">
              <h3>Our creators</h3>
              <div className="name">{creators[phoneSliderIndex].name}</div>
            </div>
            <div className="slider-navigation">
              <div
                className="arrow"
                role="button"
                aria-label="previous photo"
                tabIndex="-1"
                onKeyDown={() => {}}
                onClick={() =>
                  setPhoneSliderIndex(
                    phoneSliderIndex > 0
                      ? phoneSliderIndex - 1
                      : creators.length - 1
                  )
                }
              ></div>
              <div
                className="arrow"
                role="button"
                aria-label="next photo"
                tabIndex="-1"
                onKeyDown={() => {}}
                onClick={() =>
                  setPhoneSliderIndex(
                    phoneSliderIndex === creators.length - 1
                      ? 0
                      : phoneSliderIndex + 1
                  )
                }
              ></div>
            </div>
            <div className="slider-content">
              <div className="scroll-wrapper">
                <div
                  className="scroll-content"
                  style={{
                    transform: `translateX(${-292 * phoneSliderIndex}px)`,
                  }}
                >
                  {creators.map(creator => (
                    <img
                      src={require(`../images/creators/${creator.name}.jpg`)}
                      alt={creator.name}
                      key={creator.name}
                    />
                  ))}
                </div>
              </div>
            </div>
            <Img
              fixed={data.phoneFrame.childImageSharp.fixed}
              objectFit="contain"
              className="slider-frame"
            />
          </div>
          <div className="content">
            <Fade right>
              <h3>About Us</h3>
              <p>
                Orlovski Agency aka Orlovage is an agency that helps influencers
                and brands in marketing via social media. We help influencers
                start making money from what they love and help brands promote
                their products/services! You want to start getting money from
                sponsorships? Maybe you want to promote your mobile game? Don’t
                hesitate! Take action!
              </p>
              <p>
                Our experience in social media and e-commerce business started
                back in 2016 from small online gaming websites where we took
                care of relations with influencers and promotion of the
                websites. We saw the opportiunity in influencers and how they
                can promote various brands. Now our agency has top mobile gaming
                / lifestyle / gaming influencers and we are still growing! We
                work with market giants but also with small companies that are
                just starting their business. It does not matter if you are big
                or small. You need our help? We are here to help you! For
                influencers we can provide monthly deals but also longterm
                contracts so they can have passive income from sponsors. On the
                other hand for brands we can prepare campaigns, promotional
                videos or even promotional posts on all social media helping
                them grow! What are you waiting on? Let’s grow together!
              </p>
            </Fade>
          </div>
        </div>
      </AboutUsWrapper>
      <StatisticsWrapper>
        <Fade
          duration={0}
          onReveal={() => {
            countUp1.start()
            countUp2.start()
            countUp3.start()
          }}
        >
          <div className="statistics-grid">
            <div className="statistics-item">
              <h4 className="statistics-header">{countUp1.countUp}</h4>
              <div className="statistics-description">
                Our clients subscribers
              </div>
            </div>
            <div className="statistics-item">
              <h4 className="statistics-header">{countUp2.countUp}</h4>
              <div className="statistics-description">
                Views of our promoted videos
              </div>
            </div>
            <div className="statistics-item">
              <h4 className="statistics-header">{countUp3.countUp}</h4>
              <div className="statistics-description">Helped companies</div>
            </div>
          </div>
        </Fade>
      </StatisticsWrapper>
      <OpinionsWrapper>
        <h3 className="heading">What people say</h3>
        <div className="opinions-grid">
          {opinions.map(({ author, content }) => (
            <div className="opinion-item" key={author}>
              <Fade bottom>
                <div className="opinion-text">
                  <div className="stars">
                    <Img
                      fixed={data.star.childImageSharp.fixed}
                      objectFit="contain"
                    />
                    <Img
                      fixed={data.star.childImageSharp.fixed}
                      objectFit="contain"
                    />
                    <Img
                      fixed={data.star.childImageSharp.fixed}
                      objectFit="contain"
                    />
                    <Img
                      fixed={data.star.childImageSharp.fixed}
                      objectFit="contain"
                    />
                    <Img
                      fixed={data.star.childImageSharp.fixed}
                      objectFit="contain"
                    />
                  </div>
                  <p>{content}</p>
                </div>
                <div className="opinion-author">
                  <div className="avatar">
                    <Avatar size={73} icon={<UserOutlined />} />
                  </div>
                  <div>
                    <div className="name">{author}</div>
                    {/* <div className="company">CompanyYoutube</div> */}
                  </div>
                </div>
              </Fade>
            </div>
          ))}
        </div>
      </OpinionsWrapper>
    </Layout>
  )
}

export default IndexPage
