import styled from "styled-components"
import arrow from "../images/slider-arrow.png"
import arrowHover from "../images/slider-arrow-hover.png"
import { Button } from "antd"

export const OfferButton = styled(Button)`
  margin-top: 35px;
  padding: 23px 48px;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-family: Raleway;
  font-size: 1.063rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ffffff;
  line-height: 1;
  --box-shadow-color: ${props => props.boxShadowColor};
  box-shadow: 0 0 35px 0px var(--box-shadow-color);
  box-sizing: border-box;
  height: auto;
  background: ${props => props.background};
  border-radius: 33px;
  border: none;

  &:hover,
  &:focus {
    border: none;
    color: white;
    background: ${props => props.background};
    --box-shadow-color: ${props => props.boxShadowColorHover};
    filter: brightness(110%);
    transform: scale(105%);
  }
`

export const OfferWrapper = styled.section`
  max-width: 1320px;
  margin: 0 auto;
  padding-top: 55px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  gap: 180px;

  .first-section-item {
    display: grid;
    justify-content: center;
    justify-items: center;
    text-align: center;

    h3 {
      margin: 48px 0 34px;
      font-family: Raleway;

      font-size: 3.25rem;

      font-weight: 800;

      font-stretch: normal;

      font-style: normal;

      line-height: 0.88;

      letter-spacing: normal;

      /* text-align: left; */

      color: #191919;
    }

    p {
      font-family: Poppins;

      font-size: 0.938rem;

      font-weight: normal;

      font-stretch: normal;

      font-style: normal;

      line-height: 1.87;

      letter-spacing: normal;

      text-align: center;

      color: #707070;
    }
  }

  @media screen and (max-width: 1320px) {
    grid-template-columns: 1fr;
    padding: 25px;
    gap: 60px;
  }
`

export const CompaniesWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  max-width: 1320px;
  margin: 70px auto 0;
  padding: 55px;
  justify-content: center;

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    filter: saturate(0);
    transition: all 0.2s ease;

    &:hover {
      filter: initial;
    }
  }

  @media screen and (max-width: 1320px) {
    grid-template-columns: 1fr;
    padding: 35px;
    gap: 25px;
    margin: 50px 0;
  }
`

export const AboutUsWrapper = styled.section`
  background-color: #f2f2f2;

  .triangle {
    width: 100%;
    height: calc(100vw / 9.6);
    background: white;
    clip-path: polygon(0% 100%, 100% 0%, 0% 0%);
  }

  .about-us-grid {
    display: grid;
    grid-template-columns: 600px 1fr;
    max-width: 1320px;
    margin: 0 auto;
  }

  .slider-wrapper {
    width: 717px;
    margin-top: -75px;
    margin-left: 85px;
    position: relative;
    user-select: none;

    .instagram,
    .twitter,
    .youtube {
      display: block;
      position: absolute;
      z-index: 5;

      transition: all 0.2s ease;

      &:hover {
        /* filter: hue-rotate(-25deg) brightness(125%); */
        transform: scale(115%);
        filter: brightness(110%);
      }
    }

    .instagram {
      top: 93px;
      right: 292px;
    }

    .twitter {
      top: 134px;
      left: 28px;
    }

    .youtube {
      bottom: 129px;
      right: 402px;
    }

    .text {
      position: absolute;
      bottom: 0;
      transform-origin: 0 100%;
      transform: rotate(-0.25turn) translateY(-25px) translateX(320px);
      z-index: 10;

      h3 {
        font-family: Raleway;

        font-size: 3.25rem;

        font-weight: 800;

        font-stretch: normal;

        font-style: normal;

        /* line-height: 0.88; */
        margin: 0;

        letter-spacing: normal;

        text-align: left;
        line-height: 1;

        color: #191919;
      }

      .name {
        font-family: Raleway;

        font-size: 1.625rem;

        font-weight: 600;

        font-stretch: normal;

        font-style: normal;

        /* line-height: 1.77; */
        margin: 0;
        letter-spacing: normal;

        text-align: left;

        color: #191919;
      }
    }

    .slider-navigation {
      position: absolute;
      display: grid;
      grid-template-columns: repeat(2, 50%);
      z-index: 10;
      top: 512px;
      left: 311px;
      width: 123px;

      height: 59px;

      border-radius: 33px;

      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);

      background-color: #2e0f7b;

      .arrow {
        background: url(${arrow});
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;

        &:hover {
          background-image: url(${arrowHover});
          transform: rotate(0.5turn);
        }

        &:nth-child(2) {
          transform: rotate(0.5turn);

          &:hover {
            transform: rotate(0);
          }
        }
      }
    }

    .slider-content {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 16px 349px 161px 76px;
      /* overflow: hidden; */

      .scroll-wrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;

        .scroll-content {
          display: flex;
          height: 100%;
          transition: transform 0.2s ease;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .slider-frame {
      z-index: 3;
      display: block !important;
    }
  }

  .content {
    // margin-top: 78px;
    padding-left: 120px;

    h3 {
      font-family: Raleway;

      font-size: 3.25rem;

      font-weight: 800;

      font-stretch: normal;

      font-style: normal;

      line-height: 0.88;

      letter-spacing: normal;

      text-align: left;

      color: #191919;
      margin-bottom: 34px;
      margin-left: 12px;
    }

    p {
      font-family: Cabin;

      font-size: 1.063rem;

      font-weight: normal;

      font-stretch: normal;

      font-style: normal;

      line-height: 2;

      letter-spacing: normal;

      text-align: left;

      color: #707070;
    }
  }

  @media screen and (max-width: 1320px) {
    /* padding: 25px; */

    .about-us-grid {
      grid-template-columns: 1fr;
      padding: 25px;
    }

    .slider-wrapper {
      display: none;
    }

    .content {
      margin: 0;
      padding: 0;
    }
  }
`

export const StatisticsWrapper = styled.section`
  background: #0b0314;
  padding: 110px 0 85px;

  .statistics-grid {
    display: grid;
    justify-content: space-between;
    /* gap: 235px; */
    grid-template-columns: repeat(3, auto);
    width: 100%;
    max-width: 1320px;
    color: white;
    margin: 0 auto;
  }

  .statistics-item {
    display: grid;
    grid-template-rows: repeat(2, auto);
    /* max-width: 320px; */
    justify-content: center;
    gap: 37px;
    text-align: center;
  }

  .statistics-header {
    font-family: Montserrat;

    font-size: 3.5rem;

    font-weight: bold;

    font-stretch: normal;

    font-style: normal;

    line-height: 1;
    margin: 0;

    letter-spacing: normal;

    color: #4d2c9d;
  }

  .statistics-description {
    font-family: Raleway;
    text-align: center;

    font-size: 1.313rem;

    font-weight: 600;

    font-stretch: normal;

    font-style: normal;

    line-height: 1.52;

    letter-spacing: normal;

    /* text-align: left; */

    color: #ffffff;
  }

  @media screen and (max-width: 1320px) {
    padding: 75px 25px;

    .statistics-grid {
      grid-template-columns: 1fr;
      gap: 45px;
      justify-items: center;
    }

    .statistics-description {
      line-height: 1.2;
    }

    .statistics-item {
      gap: 10px;
      text-align: center;
      max-width: auto;
    }

    .statistics-header {
      text-align: center;
    }
  }
`

export const OpinionsWrapper = styled.section`
  max-width: 1320px;
  margin: 0 auto;
  padding: 75px 0 120px;

  .heading {
    font-family: Raleway;

    font-size: 3.25rem;

    font-weight: 800;

    font-stretch: normal;

    font-style: normal;

    line-height: 1;

    letter-spacing: normal;

    text-align: center;

    color: #121216;
    margin-bottom: 83px;
  }

  .opinions-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 47px;
    row-gap: 73px;
  }

  .opinion-item {
    display: grid;
    grid-template-rows: 1fr auto;
  }

  .opinion-text {
    padding: 34px 26px 48px;
    border: solid 1px #bcbcbc;
    position: relative;
    margin-bottom: 18px;
    background: white;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 32px;
      height: 32px;
      transform: translateX(85px) rotate(45deg);
      background: white;
      border: solid 1px #bcbcbc;
      border-left-color: transparent;
      border-top-color: transparent;
      bottom: -16px;
      z-index: 1;
    }

    .stars {
      display: grid;
      grid-auto-flow: column;
      justify-content: start;
      gap: 8px;
      margin-bottom: 16px;
    }

    p {
      font-family: Poppins;

      font-size: 0.938rem;

      font-weight: normal;

      font-stretch: normal;

      font-style: normal;

      line-height: 1.87;

      letter-spacing: normal;

      text-align: left;

      color: #707070;
    }
  }

  .opinion-author {
    padding-left: 15px;
    display: grid;
    grid-template-columns: 73px auto;
    column-gap: 15px;
    align-items: center;
  }

  .name {
    font-family: Poppins;

    font-size: 1.063rem;

    font-weight: 600;

    font-stretch: normal;

    font-style: normal;

    line-height: 1

    letter-spacing: normal;

    text-align: left;

    color: #000000;
  }

  .company {
    font-family: Poppins;

    font-size: 0.938rem;

    font-weight: normal;

    font-stretch: normal;

    font-style: normal;

    line-height: 1.1;

    letter-spacing: normal;

    text-align: left;

    color: #000000;
  }

  @media screen and (max-width: 1320px) {
    padding: 75px 25px;

    .opinions-grid {
      grid-template-columns: 1fr;
    }
  }
`
