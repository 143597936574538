import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import Img from "gatsby-image"
import Bounce from "react-reveal/Bounce"
import Fade from "react-reveal/Fade"
import Roll from "react-reveal/Roll"

import {
  HeaderWrapper,
  ContactButton,
  HeaderItems,
  HeaderTriangle,
  HeaderContentWrapper,
  MainHeaderContent,
} from "./Header.styles"
import NavigationComponent from "../Navigation/Navigation.component"

export default props => {
  const data = useStaticQuery(graphql`
    query {
      headerBackground: file(relativePath: { eq: "header-background.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      headerPhone: file(relativePath: { eq: "header-phone.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }

      headerIcons: file(relativePath: { eq: "header-icons.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }

      strategy: file(relativePath: { eq: "strategy.png" }) {
        childImageSharp {
          fixed(height: 62) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      effects: file(relativePath: { eq: "effects.png" }) {
        childImageSharp {
          fixed(height: 62) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      creative: file(relativePath: { eq: "creative.png" }) {
        childImageSharp {
          fixed(height: 62) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <HeaderWrapper>
      <HeaderContentWrapper fluid={data.headerBackground.childImageSharp.fluid}>
        <NavigationComponent />
        <MainHeaderContent>
          <div className="content">
            <Fade bottom duration={300}>
              <h1>Backbone of influencers face of the brands</h1>
            </Fade>
            <Fade left delay={300} duration={300}>
              <p>
                Orlovski Agency aka Orlovage is an agency that helps influencers
                and brands in marketing via social media. We help influencers
                start making money from what they love and help brands promote
                their products/services! You want to start getting money from
                sponsorships? Maybe you want to promote your mobile game? Don’t
                hesitate! Contact us so we can work on a deal.
              </p>
            </Fade>
            <Fade right delay={600} duration={300}>
              <div className="buttons">
                <Link to="/contact">
                  <ContactButton>CONTACT US</ContactButton>
                </Link>
                {/* <a
                  href="https://twitter.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TweetUs type="link">TWEET US</TweetUs>
                </a> */}
              </div>
            </Fade>
          </div>
          <Fade left delay={700} duration={400}>
            {/* <Img
            className="header-icons"
            fluid={data.headerIcons.childImageSharp.fluid}
            objectFit="cover"
          /> */}
            <img
              src={require("../../images/header-icons.svg")}
              alt="icons"
              className="header-icons"
            />
          </Fade>
          <Bounce right duration={700}>
            {/* <Img
            className="header-phone"
            fluid={data.headerPhone.childImageSharp.fluid}
            objectFit="cover"
          /> */}
            <img
              src={require("../../images/header-phone.svg")}
              alt="phone"
              className="header-phone"
            />
          </Bounce>
        </MainHeaderContent>
      </HeaderContentWrapper>
      <HeaderItems>
        <Fade bottom duration={600}>
          <div className="header-item">
            <Img
              fixed={data.effects.childImageSharp.fixed}
              objectFit="contain"
            />
            <h4>
              Amazing
              <br />
              Effects
            </h4>
            <p>
              Our agency can not only bring money to youtuber’s but get your
              product/service known in the world so it can start generating
              bigger profits.
            </p>
          </div>
        </Fade>
        <Fade bottom delay={450} duration={600}>
          <div className="header-item">
            <Img
              fixed={data.strategy.childImageSharp.fixed}
              objectFit="contain"
            />
            <h4>
              Super Marketing
              <br />& Strategy
            </h4>
            <p>
              We not only use youtube or twitch. Our clients can promote your
              brand via twitter,instagram,tiktok and more! This will bring you
              even more customers for the same price!
            </p>
          </div>
        </Fade>
        <Fade bottom delay={900} duration={600}>
          <div className="header-item">
            <Img
              fixed={data.creative.childImageSharp.fixed}
              objectFit="contain"
            />
            <h4>
              Creative
              <br />& Media
            </h4>
            <p>
              You don’t have an idea for your campaign? No worries we will take
              care of it! We can offer things like creating content, creating
              videos, creating sponsored posts. So you won’t have to worry about
              it!
            </p>
          </div>
        </Fade>
      </HeaderItems>
      <HeaderTriangle>
        <div className="triangle" />
        <Roll left>
          <div className="wrapper">
            <span>WHAT WE DO</span>
          </div>
        </Roll>
      </HeaderTriangle>
    </HeaderWrapper>
  )
}
